
import Component from 'vue-class-component'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);
import { Vue, Watch } from 'vue-property-decorator';
import InviteCard from '@/components/invite/InviteCard.vue';
import Button from '@/components/ui/button/Button.vue';
import { InviteData } from '@/models/invite';
import GeneralModule from '@/store/modules/general/GeneralModule';
import ROUTES from '@/router/ROUTES';
import { andCombiner, email, required } from '@/services/validators';
import Notify from '@/services/helpers/notify';
import ConfirmPopup from '@/components/context-menu/repeatable/ConfirmPopup.vue';
import router from '@/router';

const createInvite = (): InviteData => ({
  email: '',
  specialisations: [],
});


@Component({
  components: { InviteCard, Button, ConfirmPopup },
})
export default class Invite extends Vue {

  public showedOnceLeavePopup = false;

  beforeRouteLeave(to: any, from:any, next:any) {
    if(this.pageMutated && !this.savePage)
    {
      this.preventPath = to.path;
      this.confirmContextVisible = true;
      if(this.confirmContextVisible && !this.showedOnceLeavePopup)
      {
        this.showedOnceLeavePopup = true;
        return;
      }
    }
    next()
  }
  
  public preventPath = '';
  public savePage = false;
  invites: Array<InviteData> = [createInvite()];
  public isFieldValid = false;
  public pageMutated = false;
  public confirmContextVisible = false;
  get emailList() {
    return this.invites.map((el) => el.email)
  }
  
  preventRedirect()
  {
    this.showedOnceLeavePopup = false;
    this.confirmContextVisible = false;
  }
  async continuePath()
  {
    this.confirmContextVisible = false
    await this.$nextTick();
    this.$router.push(this.preventPath)
  }
  beforeDestroy()
  {
    this.confirmContextVisible = true;
  }
  addOneMore() {
    this.invites.push(createInvite());
  }
  removeEmptyDuplicat() {
    if (this.invites[0].email.length >= 6 && this.invites[0].specialisations.length >= 1)
    {
      this.inviteAll()
      this.invites = this.invites.filter(invite =>
      {
        return invite.email !== '' && invite.specialisations.length > 0;
      });
    }
  }

  validate(el: InviteData) {
    this.isFieldValid = andCombiner([required, email]).validate(el.email).isValid;

    for (const index in this.invites) {
      const [el] = this.$refs['invite' + index] as Array<InviteCard>;
      // if(el.isDuplicate() === true) this.isFieldValid = false;
      el.validate();
    }
  }
  async inviteAll() {
    const BreakException = {};
    try 
    {
      this.invites.forEach((el) => 
      {
        this.validate(el)
        if(!this.isFieldValid)
          throw BreakException
      })
    }
    catch(e)
    {
      if (e !== BreakException) throw e;
    }
    if(this.isFieldValid)
    { 
      if(this.invites.filter((el) => 
      {
        return !el.specialisations.length && el.email
      }).length)
      {
        Notify.error('Роль обязательна к заполнению');
        return;
      }
      else
      {
        this.savePage = true;
        await GeneralModule.invitePeoples(this.invites).then(() => 
        {
          Notify.success('Приглашение отправлено');
        });
        this.$router.push(ROUTES.HOME);
      }
    }
  }
  onInviteChange(index: number, invite: InviteData) 
  {
    this.pageMutated = true;
    this.invites[index] = invite;
  }

  /**
   * Test output
   */
  @Watch('invites')
  onInvitesChange() {
    console.debug(this.invites);
  }
}
